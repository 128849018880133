<!--
 * @Author: Trigger
 * @Date: 2022-01-20 11:40:15
 * @LastEditTime: 2022-01-20 11:47:04
 * @Description: file content
-->
<template>
  <div class="commoncontent">
    <slot name="header"></slot>
    <slot></slot>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";

export default {
  setup() {
    const state = reactive({
      message: "基础信息",
    });

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="less" scoped>
.commoncontent {
  width: 100%;
  height: 100%;
}
</style>
