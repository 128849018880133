<!--
 * @Author: Trigger
 * @Date: 2022-01-20 11:36:27
 * @LastEditTime: 2022-02-16 14:44:16
 * @Description: file content
-->

<template>
  <div class="leftbox">
    <Commoncontent>
      <!--头部开始-->
      <template v-slot:header>
        <div class="headertitle">关注问题</div>
      </template>
      <!--头部结束-->
      <!--内容开始-->
      <template v-slot>
        <div class="echart">
          <div id="righttwo" :style="{ width: '100%', height: '100%' }"></div>
        </div>
      </template>
      <!--内容结束-->
    </Commoncontent>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";

import Commoncontent from "@/components/common";

import * as echarts from "echarts";

export default {
  components: {
    Commoncontent,
  },
  setup() {
    const getfontsize = (fontSize) => {
      console.log(window.innerWidth);
      const widthresult = window.innerWidth;
      const result = ((widthresult / 22) * fontSize) / 100;
      return result;
    };
    const echartinit = () => {
      //找到Echart元素
      const chartDom = document.getElementById("righttwo");
      let myChart = echarts.init(chartDom, "light"); //默认的时候支持light或者dark两种样式
      window.addEventListener("resize", function () {
        myChart.resize();
      });
      const color = [
        "#eb3600",
        "#00e9db",
        "#34da62",
        "#d0a00e",
        "#001676",
        "#886EFF",
      ];

      const legend = [
        "发布新品",
        "产品包邮",
        "质量问题",
        "服务态度",
        "优惠活动",
        "其他",
      ];

      const seriesData = [
        { value: 12, name: "发布新品" },
        { value: 1, name: "产品包邮" },
        { value: 4, name: "质量问题" },
        { value: 2, name: "服务态度" },
        { value: 2, name: "优惠活动" },
        { value: 3, name: "其他" },
      ];

      myChart.setOption({
        color: color,
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
          textStyle: {
            fontSize: getfontsize(14),
          },
        },

        legend: {
          bottom: "0%",
          // 修改小图标的大小
          itemWidth: 10,
          itemHeight: 10,
          // 修改图例组件的文字为 12px
          textStyle: {
            color: "rgba(255,255,255,.5)",
            fontSize: getfontsize(14),
          },
          data: legend,
        },
        series: [
          {
            name: "关注问题",
            type: "pie",
            // 这个radius可以修改饼形图的大小
            // radius 第一个值是内圆的半径 第二个值是外圆的半径
            radius: ["40%", "60%"],
            center: ["50%", "45%"],
            avoidLabelOverlap: false,
            // 图形上的文字
            label: {
              normal: {
                show: false,
                position: "center",

                rich: {
                  value: {
                    padding: 5,
                    align: "center",
                    verticalAlign: "middle",
                    fontSize: getfontsize(16),
                  },
                  label: {
                    align: "center",
                    verticalAlign: "middle",
                    fontSize: getfontsize(16),
                  },
                },
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: getfontsize(12),
                  color: "rgba(255,255,255,0.6)",
                },
              },
            },

            // 链接文字和图形的线是否显示
            labelLine: {
              show: false,
            },
            data: seriesData,
          },
        ],
      });
      getDefaultSelected(myChart);
    };
    //赋值设定初始值,一进一出都需要赋值
    const getDefaultSelected = (myChart) => {
      let index = 0;
      myChart.dispatchAction({
        type: "highlight",
        seriesIndex: 0,
        dataIndex: 0,
      });
      myChart.on("mouseover", (e) => {
        if (e.dataIndex !== index) {
          myChart.dispatchAction({
            type: "downplay",
            seriesIndex: 0,
            dataIndex: index,
          });
        }
      });
      myChart.on("mouseout", (e) => {
        index = e.dataIndex;
        myChart.dispatchAction({
          type: "highlight",
          seriesIndex: 0,
          dataIndex: e.dataIndex,
        });
      });
    };

    const state = reactive({
      message: "第一个",
    });

    onMounted(() => {
      echartinit();
    });

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="less" scoped>
.leftbox {
  width: 100%;
  height: 100%;
  .headertitle {
    color: white;
    width: 100%;
    height: 0.3125rem;
    line-height: 0.3125rem;
    font-size: 0.0859rem;
    text-align: center;
  }
  .echart {
    color: white;
    width: 95%;
    margin: 0 auto;

    height: calc(100% - 0.4125rem);
  }
}
</style>
