<!--
 * @Author: Trigger
 * @Date: 2022-01-20 17:17:42
 * @LastEditTime: 2022-02-16 14:47:36
 * @Description: file content
-->
<template>
  <div class="contentbox">
    <div class="allbox">
      <div class="numbox">
        <div class="lefttop"></div>
        <div class="rightbottom"></div>
        <div class="numall">
          <div class="numall1">
            <Numall1></Numall1>
          </div>
          <div class="fenge"></div>
          <div class="numall2">
            <Numall2></Numall2>
          </div>
        </div>
      </div>
      <div class="numcontent">
        <div class="leftcontent">订单数量</div>
        <div class="rightcontent">会员数量</div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import Numall1 from "@/components/numall1";
import Numall2 from "@/components/numall2";
export default {
  components: {
    Numall1,
    Numall2,
  },
  setup() {
    const state = reactive({
      message: "数字模块",
    });

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="less" scoped>
.contentbox {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .allbox {
    width: calc(100% - 0.0781rem);
    height: calc(100% - 0.0781rem);
    margin: 0 auto;
    margin-top: 0.0391rem;
    display: flex;
    flex-flow: column nowrap;
    .numbox {
      flex: 0 0 70%;
      border: 1px solid #182265;
      position: relative;
      .lefttop {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 50px;
        height: 20px;
        border-top: 2px solid #3a88a8;
        border-left: 2px solid #3a88a8;
      }
      .rightbottom {
        position: absolute;
        right: 0px;
        bottom: 0px;
        width: 50px;
        height: 20px;
        border-bottom: 2px solid #3a88a8;
        border-right: 2px solid #3a88a8;
      }
      .numall {
        width: 100%;
        height: 100%;
        display: flex;
        flex-flow: row nowrap;
        overflow: hidden;
        justify-content: center;
        align-items: center;
        .numall1 {
          flex: 1;
        }
        .fenge {
          flex: 0 0 1px;
          height: 60%;

          background: rgba(255, 255, 255, 0.4);
        }
        .numall2 {
          flex: 1;
        }
      }
    }
    .numcontent {
      flex: 1;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      div {
        flex: 1;
        text-align: center;
        font-size: 0.0703rem;
        color: rgba(255, 255, 255, 1);
        letter-spacing: 0.0078rem;
      }
    }
  }
}
</style>
