<!--
 * @Author: Trigger
 * @Date: 2022-01-20 11:36:27
 * @LastEditTime: 2024-02-09 11:35:44
 * @Description: file content
-->

<template>
  <div class="leftbox">
    <Commoncontent>
      <!--头部开始-->
      <template v-slot:header>
        <div class="headertitle">用户年龄</div>
      </template>
      <!--头部结束-->
      <!--内容开始-->
      <template v-slot>
        <div class="echart">
          <div id="leftThree" :style="{ width: '100%', height: '100%' }"></div>
        </div>
      </template>
      <!--内容结束-->
    </Commoncontent>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";

import Commoncontent from "@/components/common";

import * as echarts from "echarts";

export default {
  components: {
    Commoncontent,
  },
  setup() {
    const getfontsize = (fontSize) => {
      console.log(window.innerWidth);
      const widthresult = window.innerWidth;
      const result = ((widthresult / 22) * fontSize) / 100;
      return result;
    };
    const echartinit = () => {
      //找到Echart元素
      const chartDom = document.getElementById("leftThree");
      let myChart = echarts.init(chartDom, "light"); //默认的时候支持light或者dark两种样式
      window.addEventListener("resize", function () {
        myChart.resize();
      });
      myChart.setOption({
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "0%",
          data: ["电源一号", "电源2号"],
          textStyle: {
            color: "rgba(255,255,255,.5)",
            fontSize: getfontsize(16),
          },
        },

        grid: {
          left: "10",
          top: "30",
          right: "10",
          bottom: "10",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            // x轴更换数据
            data: [
              "2010",
              "2011",
              "2012",
              "2013",
              "2014",
              "2015",
              "2016",
              "2017",
              "2018",
              "2019",
              "2020",
              "2021",
            ],
            // 文本颜色为rgba(255,255,255,.6)  文字大小为 12
            axisLabel: {
              textStyle: {
                color: "rgba(255,255,255,.6)",
                fontSize: getfontsize(14),
              },
            },
            // x轴线的颜色为   rgba(255,255,255,.2)
            axisLine: {
              lineStyle: {
                color: "rgba(255,255,255,.2)",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisTick: { show: false },
            axisLine: {
              lineStyle: {
                color: "rgba(255,255,255,.1)",
              },
            },
            axisLabel: {
              textStyle: {
                color: "rgba(255,255,255,.6)",
                fontSize: getfontsize(14),
              },
            },
            // 修改分割线的颜色
            splitLine: {
              lineStyle: {
                color: "rgba(255,255,255,.1)",
              },
            },
          },
        ],
        series: [
          {
            name: "羽绒服",
            type: "line",
            smooth: true,
            // 单独修改当前线条的样式
            lineStyle: {
              color: "#0184d5",
              width: "2",
            },
            // 填充颜色设置
            areaStyle: {
              color: new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: "rgba(1, 132, 213, 0.4)", // 渐变色的起始颜色
                  },
                  {
                    offset: 0.8,
                    color: "rgba(1, 132, 213, 0.1)", // 渐变线的结束颜色
                  },
                ],
                false
              ),
              shadowColor: "rgba(0, 0, 0, 0.1)",
            },
            // 设置拐点
            symbol: "circle",
            // 拐点大小
            symbolSize: 8,
            // 开始不显示拐点， 鼠标经过显示
            showSymbol: false,
            // 设置拐点颜色以及边框
            itemStyle: {
              color: "#0184d5",
              borderColor: "rgba(221, 220, 107, .1)",
              borderWidth: 12,
            },
            data: [
              30, 40, 30, 40, 30, 40, 30, 60, 20, 40, 30, 40, 30, 40, 30, 40,
              30, 60, 20, 40, 30, 40, 30, 40, 30, 40, 20, 60, 50, 40,
            ],
          },
          {
            name: "马甲",
            type: "line",
            smooth: true,
            lineStyle: {
              normal: {
                color: "#00d887",
                width: 2,
              },
            },
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(0, 216, 135, 0.4)",
                    },
                    {
                      offset: 0.8,
                      color: "rgba(0, 216, 135, 0.1)",
                    },
                  ],
                  false
                ),
                shadowColor: "rgba(0, 0, 0, 0.1)",
              },
            },
            // 设置拐点 小圆点
            symbol: "circle",
            // 拐点大小
            symbolSize: 5,
            // 设置拐点颜色以及边框
            itemStyle: {
              color: "#00d887",
              borderColor: "rgba(221, 220, 107, .1)",
              borderWidth: 12,
            },
            // 开始不显示拐点， 鼠标经过显示
            showSymbol: false,
            data: [
              50, 51, 52, 54, 56, 53, 55, 57, 68, 70, 70, 74, 77, 53, 77, 60,
              68, 52, 43, 44, 66, 45, 79, 61, 52, 47, 38, 59, 43, 56,
            ],
          },
        ],
      });
    };

    const state = reactive({
      message: "第一个",
    });

    onMounted(() => {
      echartinit();
    });
    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="less" scoped>
.leftbox {
  width: 100%;
  height: 100%;
  .headertitle {
    color: white;
    width: 100%;
    height: 0.3125rem;
    line-height: 0.3125rem;
    font-size: 0.0859rem;
    text-align: center;
  }
  .echart {
    color: white;
    width: 95%;
    margin: 0 auto;
    height: calc(100% - 0.4125rem);
  }
}
</style>
