<!--
 * @Author: Trigger
 * @Date: 2022-01-20 11:36:27
 * @LastEditTime: 2024-02-09 11:35:06
 * @Description: file content
-->

<template>
  <div class="leftbox">
    <Commoncontent>
      <!--头部开始-->
      <template v-slot:header>
        <div class="headertitle">订单数量</div>
      </template>
      <!--头部结束-->
      <!--内容开始-->
      <template v-slot>
        <div class="echart">
          <div id="leftOne" :style="{ width: '100%', height: '100%' }"></div>
        </div>
      </template>
      <!--内容结束-->
    </Commoncontent>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from "vue";

import Commoncontent from "@/components/common";

import * as echarts from "echarts";

export default {
  components: {
    Commoncontent,
  },
  setup() {
    const getfontsize = (fontSize) => {
      console.log(window.innerWidth);
      const widthresult = window.innerWidth;
      const result = ((widthresult / 22) * fontSize) / 100;
      return result;
    };
    const echartinit = () => {
      //找到Echart元素
      const chartDom = document.getElementById("leftOne");
      let myChart = echarts.init(chartDom, "light"); //默认的时候支持light或者dark两种样式
      window.addEventListener("resize", function () {
        myChart.resize();
      });
      myChart.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          textStyle: {
            fontSize: getfontsize(14),
          },
        },
        grid: {
          left: "0%",
          top: "10px",
          right: "0%",
          bottom: "0%",
          //是否显示数值
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: [
              "电源1号",
              "电源2号",
              "电源3号",
              "电源4号",
              "电源5号",
              "电源6号",
            ],
            //保证刻度对齐
            axisTick: {
              alignWithLabel: true,
            },
            //设置X轴标签文字样式
            //x轴的文字颜色和大小
            axisLabel: {
              color: "rgba(255,255,255,0.6)",
              fontSize: getfontsize(12),
            },
            //x轴样式不显示
            axisLine: {
              show: true,
              //如果设置单独的线条样式
              // lineStyle: {
              //   color: "rgba(255,255,255,0.2)",
              //   width: 1,
              //   type: "solid",
              // },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            //设置文字
            axisLabel: {
              color: "rgba(255,255,255,0.6)",
              fontSize: getfontsize(12),
            },
            axisLine: {
              //如果设置单独的线条样式
              lineStyle: {
                color: "rgba(255,255,255,0.1)",
                width: 1,
                type: "solid",
              },
            },
            //分割线
            splitLine: {
              lineStyle: {
                color: "rgba(255,255,255,0.1)",
              },
            },
          },
        ],
        series: [
          {
            name: "Direct",
            type: "bar",
            barWidth: "35%",
            data: [3564, 3213, 2078, 2213, 578, 653],
            //修改圆角
            itemStyle: {
              //修改注意圆角
              barBorderRadius: 5,
            },
          },
        ],
        color: ["#2f89cf"],
      });
    };

    const state = reactive({
      message: "第一个",
    });

    onMounted(() => {
      echartinit();
    });
    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="less" scoped>
.leftbox {
  width: 100%;
  height: 100%;
  .headertitle {
    color: white;
    width: 100%;
    height: 0.3125rem;
    line-height: 0.3125rem;
    font-size: 0.0859rem;

    text-align: center;
  }
  .echart {
    color: white;
    width: 95%;
    margin: 0 auto;

    height: calc(100% - 0.4125rem);
  }
}
</style>
