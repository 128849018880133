<!--
 * @Author: Trigger
 * @Date: 2022-01-19 10:17:15
 * @LastEditTime: 2022-01-20 17:24:41
 * @Description: file content
-->
<template>
  <div class="homecontent">
    <div class="header">
      <h1 class="title">{{ message }}</h1>
      <div class="timer">{{ timer }}</div>
    </div>
    <!--内容开始-->
    <div class="bodycontent">
      <div class="part1">
        <div class="part1_1">
          <div class="part1_lefttop"></div>
          <div class="part1_righttop"></div>
          <div class="part1_bottomleft"></div>
          <div class="part1_bottomright"></div>
          <LeftOne></LeftOne>
        </div>
        <div class="part1_1">
          <div class="part1_lefttop"></div>
          <div class="part1_righttop"></div>
          <div class="part1_bottomleft"></div>
          <div class="part1_bottomright"></div>
          <LeftTwo></LeftTwo>
        </div>
        <div class="part1_1">
          <div class="part1_lefttop"></div>
          <div class="part1_righttop"></div>
          <div class="part1_bottomleft"></div>
          <div class="part1_bottomright"></div>
          <Leftthree></Leftthree>
        </div>
      </div>
      <!--第二部分开始-->
      <div class="part2">
        <!--头部数字开始-->
        <div class="part2num">
          <Part2num></Part2num>
        </div>
        <!--头部数字结束-->
        <!--内容开始-->
        <div class="part2content">
          <Part2content></Part2content>
        </div>
        <!--内容结束-->
      </div>
      <!--第二部分结束-->
      <div class="part3">
        <div class="part1_1">
          <div class="part1_lefttop"></div>
          <div class="part1_righttop"></div>
          <div class="part1_bottomleft"></div>
          <div class="part1_bottomright"></div>
          <Rightone></Rightone>
        </div>
        <div class="part1_1">
          <div class="part1_lefttop"></div>
          <div class="part1_righttop"></div>
          <div class="part1_bottomleft"></div>
          <div class="part1_bottomright"></div>
          <Righttwo></Righttwo>
        </div>
        <div class="part1_1">
          <div class="part1_lefttop"></div>
          <div class="part1_righttop"></div>
          <div class="part1_bottomleft"></div>
          <div class="part1_bottomright"></div>
          <Rightthree></Rightthree>
        </div>
      </div>
    </div>
    <!--内容结束-->
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from "vue";
import moment from "moment";
import LeftOne from "@/components/leftOne";
import LeftTwo from "@/components/leftTwo";
import Leftthree from "@/components/leftthree";
import Rightone from "@/components/rightone";
import Righttwo from "@/components/righttwo";
import Rightthree from "@/components/rightthree";
import Part2content from "@/components/part2content";
import Part2num from "@/components/part2num";
export default {
  components: {
    LeftOne,
    LeftTwo,
    Leftthree,
    Rightone,
    Righttwo,
    Rightthree,
    Part2content,
    Part2num,
  },
  setup() {
    onMounted(() => {
      setInterval(() => {
        const result = moment().format("YYYY-MM-DD HH:mm:ss");
        state.timer = result;
      }, 1000);
    });
    const state = reactive({
      message: "数据展示系统",
      timer: "",
    });

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="less" scoped>
.homecontent {
  .header {
    height: 0.3906rem;
    background: url(../assets/images/head_bg.png);
    background-size: 100% 100%;
    position: relative;
    color: white;
    text-align: center;
    line-height: 0.3125rem;
    font-size: 0.1563rem;
    .title {
      position: absolute;
      left: 50%;
      height: 100%;
      transform: translate(-50%);
    }
    .timer {
      position: absolute;
      right: 20px;
      height: 100%;
      font-size: 0.1094rem;
    }
  }
  .bodycontent {
    min-height: calc(100vh - 0.4687rem);
    display: flex;
    flex-flow: row nowrap;
    padding-top: 0.0781rem;
    padding-left: 0.0781rem;
    padding-right: 0.0781rem;
    .part1 {
      flex: 3;
      display: flex;
      flex-flow: column nowrap;
      margin-right: 10px;
      .part1_1 {
        width: 100%;
        flex: 1;
        background: url(../assets/images/line.png) no-repeat;
        background-size: 100% 100%;
        border: 1px solid #121d49;
        margin-bottom: 10px;
        position: relative;
        .part1_lefttop {
          position: absolute;
          top: 0px;
          left: 0px;
          border-top: 2px solid #3a88a8;
          border-left: 2px solid #3a88a8;
          width: 10px;
          height: 10px;
        }
        .part1_righttop {
          position: absolute;
          top: 0px;
          right: 0px;
          border-top: 2px solid #3a88a8;
          border-right: 2px solid #3a88a8;
          width: 10px;
          height: 10px;
        }
        .part1_bottomleft {
          position: absolute;
          bottom: 0px;
          left: 0px;
          border-bottom: 2px solid #3a88a8;
          border-left: 2px solid #3a88a8;
          width: 10px;
          height: 10px;
        }
        .part1_bottomright {
          position: absolute;
          bottom: 0px;
          right: 0px;
          border-bottom: 2px solid #3a88a8;
          border-right: 2px solid #3a88a8;
          width: 10px;
          height: 10px;
        }
      }
    }
    .part2 {
      flex: 5;
      display: flex;
      flex-flow: column nowrap;
      margin-right: 10px;
      .part2num {
        width: 100%;
        flex: 0 0 0.7813rem;
        background-color: rgba(111, 111, 111, 0.2);
      }
      .part2content {
        width: 100%;
        flex: 1;
      }
    }
    .part3 {
      flex: 3;
      display: flex;
      flex-flow: column nowrap;
      .part1_1 {
        width: 100%;
        flex: 1;
        background: url(../assets/images/line.png) no-repeat;
        background-size: 100% 100%;
        border: 1px solid #121d49;
        margin-bottom: 10px;
        position: relative;
        .part1_lefttop {
          position: absolute;
          top: 0px;
          left: 0px;
          border-top: 2px solid #3a88a8;
          border-left: 2px solid #3a88a8;
          width: 10px;
          height: 10px;
        }
        .part1_righttop {
          position: absolute;
          top: 0px;
          right: 0px;
          border-top: 2px solid #3a88a8;
          border-right: 2px solid #3a88a8;
          width: 10px;
          height: 10px;
        }
        .part1_bottomleft {
          position: absolute;
          bottom: 0px;
          left: 0px;
          border-bottom: 2px solid #3a88a8;
          border-left: 2px solid #3a88a8;
          width: 10px;
          height: 10px;
        }
        .part1_bottomright {
          position: absolute;
          bottom: 0px;
          right: 0px;
          border-bottom: 2px solid #3a88a8;
          border-right: 2px solid #3a88a8;
          width: 10px;
          height: 10px;
        }
      }
    }
  }
}
</style>
