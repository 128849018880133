<!--
 * @Author: Trigger
 * @Date: 2022-01-20 20:39:17
 * @LastEditTime: 2022-01-20 22:24:38
 * @Description: file content
-->

<template>
  <div>
    <div class="chart-m-l-c">
      <div class="count">
        <b v-for="(item, index) in prosecutorArr" :key="index">
          <div :style="{ top: -item * elheight + 'px' }">
            <i v-for="(ic, indexc) in 10" :key="indexc" :ref="setItemRef">{{
              ic - 1
            }}</i>
          </div>
        </b>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, ref, computed } from "vue";

export default {
  setup() {
    let itemRefs = [];
    const setItemRef = (el) => {
      if (el) {
        itemRefs.push(el);
      }
    };
    onMounted(() => {
      console.log(itemRefs[0].offsetHeight);
      data.elheight = itemRefs[0].offsetHeight;
      window.addEventListener("resize", function () {
        data.elheight = itemRefs[0].offsetHeight;
      });

      setInterval(() => {
        data.refreshLeft();
      }, 1000);
    });

    const data = reactive({
      elheight: "",
      message: "首页",
      prosecutorArr: ["0", "0", "0", "0", "0", "0", "0", "0"],
      //这里的数字数组是个8位数，只是占位，不能直接使用，看需求有可能是10位或11、12、20等等
      numbers: "59832",
      //这个参数是后台传给我们的，也不能直接使用，需要转换
      PrefixInteger(num, length) {
        //给数字前面补零 比如‘59832’补成8位即位‘00059832’
        return (Array(length).join("0") + num).slice(-length);
      },
      getRandomNumber(min, max) {
        //为了看效果，refreshLeft触发增加数字看效果，和后台数据过来效果一样，不管增加或减少
        return Math.floor(Math.random() * (max - min + 1) + min);
      },
      plusNPrAll() {
        const res = data.PrefixInteger(data.numbers, 8);
        data.prosecutorArr = res.toString().split("");
      },
      refreshLeft() {
        //刷新数据，我这里因为静态看效果，联调时将请求写在里面就好了
        data.numbers = parseInt(data.numbers) + data.getRandomNumber(1, 1000);
        data.plusNPrAll();
      },
    });

    return {
      ...toRefs(data),
      setItemRef,
    };
  },
};
</script>

<style lang="less" scoped>
.chart-m-l-c {
  width: 100%;
  height: 0.2344rem;
  padding: 10px 0;
  float: left;
  label {
    line-height: 40px;
    float: left;
    font-size: 13px;
    font-weight: 600;
  }
  .count {
    height: 0.2344rem;
    display: flex;
    padding: 0 5px;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    b {
      flex: 1;
      margin-right: 5px;
      height: 0.2344rem;
      float: left;

      color: #000;
      text-align: center;
      line-height: 0.2344rem;
      margin: 0 2px;
      position: relative;
      z-index: 3;
      overflow: hidden;
      div {
        width: 100%;
        height: 400px;
        position: absolute;
        left: 0;
        top: 0;
        transition: all 0.8s ease-in-out;
        i {
          width: 100%;
          height: 0.2344rem;
          float: left;
          font-style: normal;
          font-size: 0.1406rem;
          color: yellow;
        }
      }
    }
  }
}
</style>
