<!--
 * @Author: Trigger
 * @Date: 2022-01-20 11:36:27
 * @LastEditTime: 2024-02-09 11:38:06
 * @Description: file content
-->

<template>
  <div class="leftbox">
    <Commoncontent>
      <!--头部开始-->
      <template v-slot:header>
        <div class="headertitle">用户最爱</div>
      </template>
      <!--头部结束-->
      <!--内容开始-->
      <template v-slot>
        <div class="echart">
          <div id="rightOne" :style="{ width: '100%', height: '100%' }"></div>
        </div>
      </template>
      <!--内容结束-->
    </Commoncontent>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";

import Commoncontent from "@/components/common";

import * as echarts from "echarts";

export default {
  components: {
    Commoncontent,
  },
  setup() {
    const getfontsize = (fontSize) => {
      console.log(window.innerWidth);
      const widthresult = window.innerWidth;
      const result = ((widthresult / 22) * fontSize) / 100;
      return result;
    };
    const echartinit = () => {
      //找到Echart元素
      const chartDom = document.getElementById("rightOne");
      let myChart = echarts.init(chartDom, "light"); //默认的时候支持light或者dark两种样式
      window.addEventListener("resize", function () {
        myChart.resize();
      });
      var myColor = [
        "#00e9db",
        "#34da62",
        "#d0a00e",
        "#eb2100",
        "#eb3600",
        "#d0570e",
      ];
      myChart.setOption({
        backgroundColor: "#0e2147",
        grid: {
          left: "11%",
          top: "12%",
          right: "10%",
          bottom: "0%",
          containLabel: true,
        },
        xAxis: [
          {
            show: false,
          },
        ],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: function (params) {
            const str =
              params[0].axisValue + "<br/>" + "热度 : " + params[0].value;
            return str;
          },
          textStyle: {
            fontSize: getfontsize(14),
          },
        },
        yAxis: [
          {
            axisTick: "none",
            axisLine: "none",
            offset: "27",
            axisLabel: {
              textStyle: {
                color: "#ffffff",
                fontSize: getfontsize(16),
              },
            },
            data: [
              "电源1号",
              "电源2号",
              "电源3号",
              "电源4号",
              "电源5号",
              "电源6号",
            ],
          },
        ],

        series: [
          {
            name: "条",
            type: "bar",
            yAxisIndex: 0,
            data: [453, 854, 1678, 2547, 3568, 4698],
            label: {
              normal: {
                show: false,
                position: "right",
                textStyle: {
                  color: "#ffffff",
                  fontSize: getfontsize(16),
                },
              },
            },
            barWidth: 12,
            itemStyle: {
              normal: {
                color: function (params) {
                  var num = myColor.length;
                  return myColor[params.dataIndex % num];
                },
              },
            },
            z: 2,
          },
          {
            name: "白框",
            type: "bar",
            yAxisIndex: 0,
            barGap: "-100%",
            data: [99.5, 99.5, 99.5, 99.5, 99.5, 99.5],
            barWidth: 10,
            itemStyle: {
              normal: {
                color: "white",
                barBorderRadius: 5,
              },
            },
            z: 1,
          },
          {
            name: "外框",
            type: "bar",
            yAxisIndex: 0,
            barGap: "-100%",
            data: [100, 100, 100, 100, 100, 100],
            barWidth: 14,
            itemStyle: {
              normal: {
                color: function (params) {
                  var num = myColor.length;
                  return myColor[params.dataIndex % num];
                },
                barBorderRadius: 5,
              },
            },
            z: 0,
          },
          {
            name: "外圆",
            type: "scatter",
            hoverAnimation: false,
            data: [0, 0, 0, 0, 0, 0],
            yAxisIndex: 0,
            symbolSize: 25,
            itemStyle: {
              normal: {
                color: function (params) {
                  var num = myColor.length;
                  return myColor[params.dataIndex % num];
                },
                opacity: 1,
              },
            },
            z: 2,
          },
        ],
      });
    };

    const state = reactive({
      message: "第一个",
    });
    onMounted(() => {
      echartinit();
    });
    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="less" scoped>
.leftbox {
  width: 100%;
  height: 100%;
  .headertitle {
    color: white;
    width: 100%;
    height: 0.3125rem;
    line-height: 0.3125rem;
    font-size: 0.0859rem;
    text-align: center;
  }
  .echart {
    color: white;
    width: 95%;
    margin: 0 auto;
    height: calc(100% - 0.4125rem);
  }
}
</style>
