<!--
 * @Author: Trigger
 * @Date: 2022-01-20 11:36:27
 * @LastEditTime: 2022-02-16 14:45:29
 * @Description: file content
-->

<template>
  <div class="leftbox">
    <Commoncontent>
      <!--头部开始-->
      <template v-slot:header>
        <div class="headertitle">用户地区分布</div>
      </template>
      <!--头部结束-->
      <!--内容开始-->
      <template v-slot>
        <div class="echart">
          <div id="rightthree" :style="{ width: '100%', height: '100%' }"></div>
        </div>
      </template>
      <!--内容结束-->
    </Commoncontent>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";

import Commoncontent from "@/components/common";

import * as echarts from "echarts";

export default {
  components: {
    Commoncontent,
  },
  setup() {
    const getfontsize = (fontSize) => {
      console.log(window.innerWidth);
      const widthresult = window.innerWidth;
      const result = ((widthresult / 22) * fontSize) / 100;
      return result;
    };
    const echartinit = () => {
      //找到Echart元素
      const chartDom = document.getElementById("rightthree");
      let myChart = echarts.init(chartDom, "light"); //默认的时候支持light或者dark两种样式
      window.addEventListener("resize", function () {
        myChart.resize();
      });
      myChart.setOption({
        color: [
          "#006cff",
          "#60cda0",
          "#ed8884",
          "#ff9f7f",
          "#0096ff",
          "#9fe6b8",
          "#32c5e9",
          "#1d9dff",
        ],
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
          textStyle: {
            fontSize: getfontsize(14),
          },
        },
        legend: {
          bottom: "0%",
          itemWidth: 10,
          itemHeight: 10,
          textStyle: {
            color: "rgba(255,255,255,.5)",
            fontSize: getfontsize(12),
          },
        },
        series: [
          {
            name: "地区分布",
            type: "pie",
            radius: ["10%", "70%"],
            center: ["50%", "50%"],
            roseType: "radius",
            // 图形的文字标签
            label: {
              textStyle: {
                color: "rgba(255,255,255,.5)",
                fontSize: getfontsize(12),
              },
            },
            // 链接图形和文字的线条
            labelLine: {
              // length 链接图形的线条
              length: 6,
              // length2 链接文字的线条
              length2: 8,
            },
            data: [
              { value: 20, name: "云南" },
              { value: 26, name: "北京" },
              { value: 24, name: "山东" },
              { value: 25, name: "河北" },
              { value: 20, name: "江苏" },
              { value: 25, name: "浙江" },
              { value: 30, name: "四川" },
              { value: 42, name: "湖北" },
            ],
          },
        ],
      });
    };

    const state = reactive({
      message: "第一个",
    });
    onMounted(() => {
      echartinit();
    });

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="less" scoped>
.leftbox {
  width: 100%;
  height: 100%;
  .headertitle {
    color: white;
    width: 100%;
    height: 0.3125rem;
    line-height: 0.3125rem;
    font-size: 0.0859rem;
    text-align: center;
  }
  .echart {
    color: white;
    width: 95%;
    margin: 0 auto;

    height: calc(100% - 0.4125rem);
  }
}
</style>
