<!--
 * @Author: Trigger
 * @Date: 2022-01-20 17:18:39
 * @LastEditTime: 2022-01-22 08:00:05
 * @Description: file content
-->
<template>
  <div class="echartbox">
    <div class="wolrdmap"></div>
    <div class="qiuti"></div>
    <div class="arrow"></div>
    <div class="mapcontent">
      <Mapcontent></Mapcontent>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import Mapcontent from "@/components/mapcontent";
export default {
  components: {
    Mapcontent,
  },
  setup() {
    const state = reactive({
      message: "地图map",
    });

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="less" scoped>
.echartbox {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .wolrdmap {
    position: absolute;
    width: 2.0234rem;
    height: 2.0234rem;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: url(../assets/images/map.png) no-repeat;
    background-size: 100% 100%;
    z-index: 1;
  }
  .qiuti {
    position: absolute;
    width: 2.5117rem;
    height: 2.5117rem;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: url(../assets/images/lbx.png) no-repeat;
    background-size: 100% 100%;
    z-index: 2;
    animation: rotate1 10s linear infinite;
  }
  .arrow {
    position: absolute;
    width: 2.2109rem;
    height: 2.2109rem;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: url(../assets/images/jt.png) no-repeat;
    background-size: 100% 100%;
    z-index: 3;
    animation: rotate2 10s linear infinite;
  }
  .mapcontent {
    width: 100%;
    height: 100%;
  }
  @keyframes rotate1 {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  @keyframes rotate2 {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(-360deg);
    }
  }
}
</style>
