<!--
 * @Author: Trigger
 * @Date: 2022-01-20 11:36:27
 * @LastEditTime: 2022-02-16 14:36:08
 * @Description: file content
-->

<template>
  <div class="leftbox">
    <Commoncontent>
      <!--头部开始-->
      <template v-slot:header>
        <div class="headertitle">用户变化</div>
      </template>
      <!--头部结束-->
      <!--内容开始-->
      <template v-slot>
        <div class="echart">
          <div id="leftTwo" :style="{ width: '100%', height: '100%' }"></div>
        </div>
      </template>
      <!--内容结束-->
    </Commoncontent>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";

import Commoncontent from "@/components/common";

import * as echarts from "echarts";

export default {
  components: {
    Commoncontent,
  },
  setup() {
    const getfontsize = (fontSize) => {
      console.log(window.innerWidth);
      const widthresult = window.innerWidth;
      const result = ((widthresult / 22) * fontSize) / 100;
      return result;
    };
    const echartinit = () => {
      //找到Echart元素
      const chartDom = document.getElementById("leftTwo");
      let myChart = echarts.init(chartDom, "light"); //默认的时候支持light或者dark两种样式
      window.addEventListener("resize", function () {
        myChart.resize();
      });
      const yearData = [
        {
          name: "会员用户", // 年份
          data: [
            // 两个数组是因为有两条线
            [24, 40, 101, 134, 90, 230, 210, 230, 120, 230, 210, 120],
            [40, 64, 191, 324, 290, 330, 310, 213, 180, 200, 180, 79],
          ],
        },
        {
          name: "新增用户", // 年份
          data: [
            // 两个数组是因为有两条线
            [123, 175, 112, 197, 121, 67, 98, 21, 43, 64, 76, 38],
            [143, 131, 165, 123, 178, 21, 82, 64, 43, 60, 19, 34],
          ],
        },
      ];
      myChart.setOption({
        // 通过这个color修改两条线的颜色
        color: ["#00f2f1", "#ed3f35"],
        tooltip: {
          trigger: "axis",
        },
        legend: {
          // 如果series 对象有name 值，则 legend可以不用写data
          // 修改图例组件 文字颜色
          textStyle: {
            color: "#4c9bfd",
            fontSize: getfontsize(16),
          },
          // 这个10% 必须加引号
          right: "10%",
        },
        grid: {
          top: "20%",
          left: "3%",
          right: "4%",
          bottom: "3%",
          show: true, // 显示边框
          borderColor: "#012f4a", // 边框颜色
          containLabel: true, // 包含刻度文字在内
        },

        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [
            "1月",
            "2月",
            "3月",
            "4月",
            "5月",
            "6月",
            "7月",
            "8月",
            "9月",
            "10月",
            "11月",
            "12月",
          ],
          axisTick: {
            show: false, // 去除刻度线
          },
          axisLabel: {
            color: "#4c9bfd", // 文本颜色
            fontSize: getfontsize(14),
          },
          axisLine: {
            show: false, // 去除轴线
          },
        },
        yAxis: {
          type: "value",
          axisTick: {
            show: false, // 去除刻度线
          },
          axisLabel: {
            color: "#4c9bfd", // 文本颜色
            fontSize: getfontsize(14),
          },
          axisLine: {
            show: false, // 去除轴线
          },
          splitLine: {
            lineStyle: {
              color: "#012f4a", // 分割线颜色
            },
          },
        },
        series: [
          {
            name: "会员用户",
            type: "line",
            // true 可以让我们的折线显示带有弧度
            smooth: true,
            data: yearData[0].data[0],
          },
          {
            name: "新增用户",
            type: "line",
            smooth: true,
            data: yearData[0].data[1],
          },
        ],
      });
    };
    const state = reactive({
      message: "第一个",
    });
    onMounted(() => {
      echartinit();
    });
    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="less" scoped>
.leftbox {
  width: 100%;
  height: 100%;
  .headertitle {
    color: white;
    width: 100%;
    height: 0.3125rem;
    line-height: 0.3125rem;
    font-size: 0.0859rem;
    text-align: center;
  }
  .echart {
    color: white;
    width: 95%;
    margin: 0 auto;
    height: calc(100% - 0.4125rem);
  }
}
</style>
